@import "../../pages/App/App.scss";

.section{
  width:100%;
  @extend %columna;
  padding:0.5rem;
  @media (min-width: $tablet) {
    padding:1rem;
  }
  @media (min-width: $grande) {
    padding:4rem;
  }
  &.autobombo{
    padding-bottom: 2rem;
    background-image: url("../../img/confeti_claro.svg");
    
    
    @media (min-width: $tablet) {
      .btn{
        width: auto;
        padding: 0.5rem;
        &:hover{
          background-color: $Color_Principal;
          color:$Color_Blanco;
          cursor:pointer;
        }
      }
    }
    @media (min-width: $desktop) {
      .btn{
        &:hover{
          background-color: $Color_Principal;
          color:$Color_Blanco;
          cursor:pointer;
        }
      }
      p{
        max-width: 59rem;
      }
    }
  }
  .degradado{
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: rgba($color: #FFFFFF, $alpha: 0.7);
  }
}
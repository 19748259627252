@import "../../pages/App/App.scss";
.footer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 0.8rem;
  color:#FFF;
  background-color: #000;
  .email{
    display:block;
    margin:0.5rem 0 0 0;
    font-size: 1.2rem;
    text-align: center;
    color:$Color_Blanco;
    text-decoration: none;
    cursor: pointer;
  }
  .redes{
    @extend %fila;
    width: 100%;
    justify-content: space-around;
    margin:1rem;
    a{
      color:$Color_Blanco;
    }
    @media (min-width: $tablet) {
      width: 60%;
      max-width: 60%;}
  }
  .copiright{
    margin: 0.5rem;
    display: flex;
    font-size: 0.8rem;
    justify-content: center;
    font-weight: normal;
    text-align: center;
  }
}

@import "../../pages/App/App.scss";

.Portada {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: $tablet) {
    flex-direction: row;
  }

  .Imagen {
    background-image: url("../../img/Anastasia_Kondratieva.png");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    height: 55vh;
    width: 100vw;

    @media (min-width: $tablet) {
      width: 55vw;
      height: 100vh;
    }
  }

  .Contenido {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px 20px;
    width: 100%;
    height: 100%;
    color: #fff;
    margin-top: -50px;
    background: linear-gradient(
      0deg,
      rgba(241, 48, 133, 1) 85%,
      rgba(241, 48, 133, 0.1253012048192771) 100%
    );

    @media (min-width: $tablet) {
      height: 100vh;
      margin-top: 0;
      background-color: $Color_Principal;
    }

    .subtitulo {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 300;
      margin: 0 0 0.5rem 0;
      text-align: center;
      @media (min-width: $tablet) {
        font-size: 2rem;
        line-height: 1.5rem;
      }
      @media (min-width: $desktop) {
        font-size: 2.5rem;
        line-height: 2rem;
      }
    }
    .h1 {
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 700;
      color: #fff;
      text-align: center;
      @media (min-width: $tablet) {
        font-size: 2.5rem;
        line-height: 2.8rem;
      }
      @media (min-width: $desktop) {
        font-size: 3rem;
      }
    }
    .presentacion_texto {
      p {
        font-size: 1rem;
        margin-bottom: 1rem;

        @media (min-width: $tablet) {
          font-size: 1.5rem;
          padding: 0 1.7rem;
        }
        @media (min-width: $desktop) {
          padding: 0 2.5rem;
        }

        a {
          color: $Color_Blanco;
          font-weight: bold;
          margin: 0;
          padding: 0;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
    .email {
      display: block;
      margin: 1rem;
      font-size: 1.2rem;
      text-align: center;
      text-decoration: none;
      color: $Color_Blanco;
      cursor: pointer;
      @media (min-width: $tablet) {
        font-size: 1.5rem;
      }
      @media (min-width: $desktop) {
        font-size: 1.7rem;
      }
    }
    .redes {
      @extend %fila;
      width: 100%;
      justify-content: center;
      @media (min-width: $desktop) {
        justify-content: space-around;
      }
      a {
        color: $Color_Blanco;
      }
    }
  }
}

// .sectionPortada {
//   width: 100%;
//   @extend %columna;
//   .contenedorPortada {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 0;
//     height: 100vh;
//     background-color: $Color_Principal;
//     width: 100%;
//     .portada {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       padding: 0;
//       height: 100vh;
//       overflow: hidden;
//       overflow-y: scroll;
//       background-color: $Color_Principal;
//       background-image: url("../../img/Anastasia_Kondratieva.png");
//       background-size: cover;
//       background-position: center;
//       background-repeat: no-repeat;
//       max-width: 1400px;
//       @media (width: $movil_v) {
//         background-position-x: 11px;
//       }
//       @media (min-width: $tablet) {
//         flex-direction: row;
//         justify-content: space-between;
//         align-items: stretch;
//         background-position-x: -173px;
//         background-size: 100vh;
//         overflow-y: hidden;
//       }
//       @media (min-width: $grande) {
//         background-position: -290px;
//       }
//       .espacio {
//         width: 0;
//       }
//       .presentacion {
//         @extend %columna;
//         width: 100%;
//         padding: 0 0.5rem;
//         color: #fff;
//         background: linear-gradient(
//           0deg,
//           rgba(241, 48, 133, 1) 85%,
//           rgba(241, 48, 133, 0.1253012048192771) 100%
//         );
//         @media (min-width: $tablet) {
//           justify-content: flex-start;
//           height: 100vh;
//           justify-content: space-evenly;
//           margin: 0;
//           width: 50%;
//           background: $Color_Principal;
//         }
//         @media (min-width: $grande) {
//           width: 61%;
//         }
//         .subtitulo {
//           font-size: 1.5rem;
//           line-height: 2rem;
//           font-weight: 300;
//           margin: 0 0 0.5rem 0;
//           text-align: center;
//           @media (min-width: $tablet) {
//             font-size: 2rem;
//             line-height: 1.5rem;
//           }
//           @media (min-width: $desktop) {
//             font-size: 2.5rem;
//             line-height: 2rem;
//           }
//         }
//         .h1 {
//           font-size: 2rem;
//           line-height: 3rem;
//           font-weight: 700;
//           color: #fff;
//           text-align: center;
//           @media (min-width: $tablet) {
//             font-size: 2.5rem;
//             line-height: 2.8rem;
//           }
//           @media (min-width: $desktop) {
//             font-size: 3rem;
//           }
//         }
//         .presentacion_texto {
//           p {
//             font-size: 1rem;
//             &:nth-of-type(1) {
//               margin-bottom: 1rem;
//             }
//             @media (min-width: $tablet) {
//               font-size: 1.5rem;
//               padding: 0 1.7rem;
//             }
//             @media (min-width: $desktop) {
//               font-size: 1.5rem;
//               padding: 0 2.5rem;
//             }
//             .enlace {
//               font-size: 1rem;
//               font-weight: normal;
//               color: $Color_Blanco;
//               margin: 0;
//               padding: 0;
//               cursor: pointer;
//               @media (min-width: $tablet) {
//                 font-size: 1.5rem;
//                 color: $Color_Blanco;
//               }
//             }
//           }
//         }
//         .email {
//           display: block;
//           margin: 1rem;
//           font-size: 1.2rem;
//           text-align: center;
//           text-decoration: none;
//           color: $Color_Blanco;
//           cursor: pointer;
//           @media (min-width: $tablet) {
//             font-size: 1.5rem;
//           }
//           @media (min-width: $desktop) {
//             font-size: 1.7rem;
//           }
//         }
//         .redes {
//           @extend %fila;
//           width: 100%;
//           justify-content: center;
//           margin: 0 0 0.5rem 0;
//           @media (min-width: $desktop) {
//             justify-content: space-around;
//           }
//           a {
//             color: $Color_Blanco;
//           }
//         }
//       }
//     }
//   }
// }

@import "../../pages/App/App.scss";
.contenedor_pata{
  position: relative;
  z-index:3;
  bottom: 0;
  right: 0;
}
@keyframes mueve {
  from {bottom: -100px;}
  to {bottom: 0;}
}
.patita{
  position: fixed;
  animation: mueve 3s;
  bottom: 0;
  right: 3px;
  width: 3.1rem;
}
@media(min-width:1440px){
  .patita{
  right: 150px;
  }
}
@import "../../pages/App/App.scss";
.section{
  width:100%;
  @extend %columna;
  padding:0.5rem;
  @media (min-width: $tablet) {
    padding:3rem;
  }
  @media (min-width: $grande) {
    padding:8rem;
  }
&.proyectos{
  background-color: $Complemtario2;
  padding-bottom: 1rem;
  .texto{
    background-color: $Complemtario2;
  }
  p{
    max-width: 59rem;
  }
}
.contenedor_proyectos{
  width: 100%;
    @media (min-width: $tablet) {
    max-width: 59rem;
    }
  }
}
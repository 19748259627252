@import "../../pages/App/App.scss";
.autobombo{
  .btn{
    background-color: $Color_Secundario_Claro;
    &:hover {
      background-color: #F13085;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
  .contenedor_autobombo {
    width: 100%;
    @extend %fila;
    justify-content: space-around;
    flex-wrap:wrap;
    @media(min-width: 728px){
      justify-content: space-evenly;
    }
    @media(min-width:$desktop){
      width:59rem;
    }
    @media(min-width:$grande){
      justify-content: space-around;
    }
  }
  .segundo{
    justify-content: space-evenly;
  }
  .filtro{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: auto;
    margin:1rem ;
    &_item{
      border:1px solid $Color_Gris;
      border-radius: 0.5rem;
      background-color: $Color_Blanco;
      color:$Color_Texto;
      padding: 0.5rem;
      margin:0.5rem;
      &:hover{
        background-color: $Color_Principal;
        color:$Color_Blanco;
        border:1px solid $Color_Principal;
      }
    }
  }
}


@import "../../pages/App/App.scss";

.contenedor_carrousel{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: $tablet) {
    flex-direction: row;
  }
}
.embla {
  overflow: hidden;
  width:100%;
  height: 100%;
  @media (min-width: $tablet) {
    width: auto;
    height: auto;
  }
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex-direction: column;
  position: relative;
  flex: 0 0 90%;
  padding: 0 0.1rem; 
  @media (min-width: $tablet) {
    position: relative;
    flex: 0 0 100%;
    padding: 0 0.1rem; 
  }
}
.embla__prev{
  display: none;
  .embla__button__svg{
    width: 20px;
    margin-right: 0.5rem;
  }
  @media (min-width: $tablet) {
  display:flex;
  margin: 2rem;
  &:hover{
  cursor: pointer;
  color:#6230F1;
  }
  }
}
.embla__next{
  display: none;
  @media (min-width: $tablet) {
    display: flex;
    margin: 0.5rem;
    &:hover{
    cursor: pointer;
    color:#6230F1;
    }
  }
}
.contenido_carrousel{
  border: 3px solid $Color_Principal;
  border-radius: 0.5rem;
  background-color:$Color_Blanco;
  display: flex;
  flex-direction:column;
  justify-content: flex-start;
  align-items: center;
  padding: 1px;
  box-sizing: border-box;
  width: 100%;
  height: 38rem;
  max-height: 38rem;
  min-height: 38rem;
  @media (min-width: $tablet) {
    border: 0.5rem solid $Color_Principal;
    border-radius: 1.5rem;
    padding: 1.5rem;
    flex-direction:row;
    align-items: flex-start;
    height: 23rem;
    max-height: 23rem;
    min-height: 23rem;
  }
  .grupo_texto{
    margin: 1rem 0 0 0;
    padding:0 0.5rem;
    @media (min-width: $tablet) {
      margin: 2.5rem 0 0 0;
    }
  }
  .grupo_img{
    margin-right: 1rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: $tablet) {
      width: 39%;
      align-items: flex-start;
      margin-right: 0;
    }
    .titulo{
      font-family:"Lato";
      font-style: normal;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $Color_Principal; 
      margin: 0.5rem; 
    }
    .img{
      width: auto;
      height: 10rem;
      margin-top: 0.5rem;
    }
  }
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
}

.embla__viewport.is-draggable {
  cursor: move;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: grabbing;
}
.embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 190px;
}

.embla__button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: #1bcacd;
  padding: 0;
}
.embla__button:disabled {
  cursor: default;
  opacity: 0.3;
}


@import "../../pages/App/App.scss";

.contenido_otros{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-color: $Color_Blanco;
  border: 2px solid $Color_Gris;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  margin-bottom: 48px;

  @media(min-width:$desktop){
    margin-bottom: 24px;
    padding: 0;
    width: 49%;
    height: 370px;
  };

  .h3{
    display: block;
    font-style: bold;
    text-transform: uppercase;
    font-weight: bold;
    line-height: 1.5rem;
    color: #F13085;
    padding: 0.3rem;
    width:auto;
    height: auto;
    text-align: left;
    margin:10px 0;

    @media (min-width: $tablet) {
      width:auto;
      height: auto;
      padding: 0;
      min-height: auto;
      max-height: auto;
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5rem;
      color: #F13085;
      padding: 0.3rem;
      height: 5rem;
      min-height: 5rem;
      max-height: 5rem;
      overflow: hidden;
      text-align: left;
    }
    @media (min-width: $desktop) {
      height:auto;
      min-height:auto;
      max-height:auto;
    }
  }

  .grupo{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 0.5rem;

    @media (min-width: $tablet){
      flex-direction: row;
      justify-content: space-between;
    }

    img {
      margin-top: 12px;
      width: 100%;

      @media (min-width: $tablet) {
        margin-top: 0;
        width: 40%;
      }
    }
    .contenedor_texto{
      width: 100%;
      @media (min-width: $tablet){
        width: 55%;
      }

      a{
        display: block;
        overflow: hidden;
        max-width: 95%;
        text-overflow: ellipsis;
        height: 1.2rem;
      }
    }
  }
}


@import "../../pages/App/App.scss";
.popup{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color:$Color_Blanco;
  width: 99vw;
  border-radius: 0.5rem;
  border:1px solid $Color_Gris;
  margin: 0.5rem;
  padding: 0.5rem;
  .btn_cerrar{
    display: flex;
    justify-content: flex-end;
    color:$Color_Principal;
    margin: 0.5rem;
    height: 1rem;
  }
  .autobombo{
    &_titulo{
        display: block;
        font-family: Lato;
        font-style: normal;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $Color_Principal;  
        padding: 0.3rem;
    }
    &_texto{
      display: block;
      padding: 0.3rem;
      color:$Color_Texto;
    }
    &_enlace{
      display: block;
      color:$Complemtario3;
      padding: 0.3rem;
    }
  }
}
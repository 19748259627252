@import "../../pages/App/App.scss";

.section{
  width:100%;
  @extend %columna;
  &.navegador{
    @extend %columna;
    padding-top:1rem;
    justify-content: flex-start;
    position:relative;
    background-color: $Color_Secundario;
    .cabecera{
      display: none;
      @media (min-width: $tablet) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
          img{
          width: 7rem;
          margin-right: 1rem;
        }
      }
      span{
        font-size: 1.5rem;
        color: #6230F1;
        font-weight: bold;
      } 
    }
    @media (min-width: $tablet) {
      background-color:#FFF;
    }
    @media (min-width: $grande) {
      background-color:#FFF;
    }
    @keyframes gato{
      from {bottom: -100px;}
      to {bottom: 0;}
    }
    .bloque_menu{
      margin-top: 0.8rem;
      width:100%;
      @extend %columna;
      padding: 3.2rem;
      z-index:1;
      justify-content: center;
      background-image: url("../../img/gato.svg");
      background-repeat: no-repeat;
      background-size: 4rem;
      background-position: top;
      height: auto;
      background-color: $Color_Secundario;
      @media (min-width: $tablet) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin:0;
        background-color:transparent;
        min-height:29rem; 
        height:auto;
        background-image: url("../../img/vector.svg"), url("../../img/gato.svg");
        background-repeat: no-repeat;
        background-size: 100%, 10%;
        background-position: center top;
      }
      @media (min-width: $desktop) {
        min-height:34rem;
      }
      @media (min-width: $grande) {
        padding: 9rem;
        width: 1032px;
        max-width: 1032px ;
        min-height: 485px;
      }
    }
    nav{
      width: 100%;
    }
    .menu{
      width: 100%;
      flex-wrap: wrap;
      @extend %fila;
      justify-content: space-evenly;
      &_item {
        @extend %columna;
        padding: 0.5rem;
      }
      @media (min-width: $tablet) {
        flex-direction: row;
        justify-content: space-around;
      }
      &_item {
        font-size: 1.3rem;
        color: $Complemtario1;
        font-weight: bold;
        text-decoration: none;
        &_enlace{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 1.3rem;
          color: $Complemtario1;
          font-weight: bold;
          text-decoration: none;
          width: 8rem;
          &:hover{
            color:$Color_Principal;
          }
        }
      }
    }
  }
}
@import "../../pages/App/App.scss";

.contenedor_paginacion{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0.5rem;
  .paginacion{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    min-width: 3rem;
    background-color: $Color_Secundario;
    border-radius: 0.5rem;
    color: $Color_Texto;
    font-weight: bold;
    .ant, .sig, .numero{
      padding: 0.5rem 0.8rem;
    }
    .numero{
      padding: 0.9rem;
    }
    .ant, .sig{
      &:hover{
        background-color: $Color_Principal;
        color:$Color_Blanco
      }
      &:disabled{
        background-color: $Color_Secundario;
        color:$Color_Gris;
      }
    }
    .ant{
      &:hover{
        border-radius: 0.5rem 0 0 0.5rem;
      }
    }
    .sig{
      &:hover{
        border-radius:0 0.5rem 0.5rem 0;
      }
    }
    .numero, .ant{
      border-right: 1px solid $Color_Texto;
    }
    .numero{
      &:hover{
        color:$Color_Blanco;
        background-color: $Color_Principal;
      }
      &:active{
        color:$Color_Blanco;
          background-color: $Color_Principal;
      }
    }
  }
}


@import "../../pages/App/App.scss";

.section{
  width:100%;
  @extend %columna;
  padding:0.3rem;
  @media (min-width: $tablet) {
    padding:0;
  }
  @media (min-width: $grande) {
    padding:0;
  }

  &.varios{
    padding-bottom: 0;
    @media (min-width: $tablet) {
      .btn{
        width: auto;
        padding: 0.5rem;
        &:hover{
          background-color: $Color_Principal;
          color:$Color_Blanco;
          cursor:pointer;
        }
      }
    }
    @media (min-width: $desktop) {
      .btn{
        &:hover{
          background-color: $Color_Principal;
          color:$Color_Blanco;
          cursor:pointer;
        }
      }
      p{
        max-width: 59rem;
      }
    }
    .fondo_varios{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.40) 0%,
      rgba(255, 255, 255, 0.40) 100%), url("../../img/libro.svg");
      width: 100%;
      @media (min-width: $tablet) {
        padding: 1rem 0;
      }
    }
    .contenedor_varios{
      margin:1rem 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (min-width: $tablet) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        
        @media(min-width:$desktop){
          width:59rem;
        };
      }
    }
  }
}
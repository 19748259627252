@import "../../pages/App/App.scss";

.articulo {
  width: 49%;

  @media (min-width: $tablet) {
    width: 33%;
  }
}
.autobombo_item{
  width: 100%;
  background-color: $Color_Blanco;
  padding-bottom: 0.5rem;  
  @media (min-width: $tablet) {
    height: 30rem;
  }
  img{
    border-radius: 0.5rem;
    border:1px solid $Color_Gris;
    width: 100%;
    height: 100px;
    object-fit: cover;
    @media (min-width: $tablet) {
      border-radius: 0.5rem 0.5rem 0 0;
      border-bottom: 1px solid $Color_Secundario;
      height: 150px;
      max-width: 100%;
      object-fit: cover;
    }
  }
  .autobombo_titulo, .autobombo_texto, .autobombo_enlace{
    display: none;
  }
  @media (min-width: $tablet) {
    width: 21rem;
    max-width: 21rem;
    min-height:350px ;
    margin-bottom:1rem ;
    border-radius: 0.5rem;
    border:1px solid $Color_Gris;
    
    .autobombo{
      &_titulo{
        display: block;
        font-family: Lato;
        font-style: normal;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: $Color_Principal;  
        padding: 0.5rem;
      }
      &_texto{
        font-size: 1rem;
        display: block;
        padding: 0.5rem;
        color:$Color_Texto;
      }
      &_enlace{
        display: block;
        color:$Complemtario3;
        padding: 0.5rem;
        li{
          margin:0.5rem;
          width: 100%;
          overflow-x: hidden;
          a{
            display: block;
            overflow: hidden;
            max-width: 95%;
            text-overflow: ellipsis;
            height: 1.2rem;
          }
        }
      }
    }
  }
  @media (min-width: $desktop) {
    width: 19rem;
  } 
}
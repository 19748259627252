:root{
  box-sizing: border-box;
}
//Colores
$Color_Principal:#F13085;
$Color_Secundario:#BBE83C;
$Color_Secundario_Claro:#d2e795;
$Complemtario1:#6230F1;
$Complemtario2:#F0FF42;
$Complemtario3:#0167FF;

$Complemtario4:#F18D30;
$Color_Texto:#2F2E41;
$Color_Gris:#C4C4C4;
$Color_Blanco:#FFFFFF;

$tablet:768px; 
$movil_v:812px;
$desktop: 1024px;
$grande:1200px;

%columna{
  display:flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center;
}
%fila{
  display:flex;
  flex-direction: row;
  justify-content:flex-start;
  align-items: center;
}
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');

html, body,* {
  font-family: 'Lato', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html{
scroll-behavior: smooth;
}

.app{
  @extend %columna;
  width: 100%;
  
  li{
    list-style: none;
    min-width: 3rem;
  }
  .enlace{
    display: inline-block;
    color:#2A46D9;
    font-weight: bold;
    margin: 0.5rem 0;
  }

  p.texto{
    font-size: 1rem;
  }

}
.btn{
  padding:1rem;
  margin: 1rem;
  background-color: $Color_Secundario;
  border-radius: 0.5rem;
  color:$Complemtario1;
  width: auto;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
}

 .main{
    width: 100%;
    @extend %columna;
  }
  
@import "../../pages/App/App.scss";
.cabecera{
  @extend %columna;
  align-items: center;
  margin: 1rem 0;
  max-width: 100%;
  @media (min-width: $tablet){
    flex-direction: row;
    width: 90%;
    justify-content: center;
  }
  .cabecera_titulo{
    @extend %fila;
    width: 100%;
    margin-right: 1rem;
    justify-content: center;
    @media (min-width: $tablet){
      width: auto;
    }
    .h2{
      font-size: 1.9rem;
      color: $Complemtario1;
      font-weight: bold;
    }
  }
  .cabecera_texto{
    margin-top: 1rem;
    font-size: 1.5rem;
    @extend %columna;
    padding: 0 0.5rem;
    @media (min-width: $tablet){
    
      max-width: 42rem;
    }

    .texto{
      margin-top: 1rem;
      color:$Color_Texto;
      background-color: $Color_Blanco;
      width: auto;
    }
    .btn{
      padding:1rem;
      margin: 1rem;
      background-color: $Color_Secundario;
      border-radius: 0.5rem;
      color:$Complemtario1;
      width: 80%;
      font-size: 1.3rem;
      font-weight: bold;
      text-align: center;
    }
  }
  .cabecera_img{
    width: 9rem;
    padding-left: 0.5rem;
  }
}


